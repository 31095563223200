import { User } from "firebase/auth";

import { ComponentEventDataMap, TypedDataComponent } from "@subgraph-io/edge/component";
import { DOM, DOMRootElement } from "@subgraph-io/edge-ui/dom/DOM";

// @ts-ignore
import MobileNavTemplate from '../../../../html-templates/mobile-nav.njk';

type SiteMobileNavComponentStoredData = {
    user: {
        replace: User | null
    }
}

type SiteMobileNavComponentEventMap = ComponentEventDataMap & {
    onSignoutChosen: null
}

export class SiteMobileNavComponent extends TypedDataComponent<SiteMobileNavComponentStoredData, SiteMobileNavComponentEventMap> implements DOMRootElement {

    protected root: DOM;

    protected navigationData: any;
    protected userNavigationData: any;
    protected onSitePage: boolean;

    constructor(navigationData: any, userNavigationData: any, mobileNavRootElement: HTMLHeadElement, onSitePage: boolean = true) {
        super();
        this.root = new DOM(mobileNavRootElement);
        this.navigationData = navigationData;
        this.userNavigationData = userNavigationData;
        this.onSitePage = onSitePage;

        this.registerForEvent('DATA_CHANGE', '', this.refresh, this);
        this.root.registerForEvent('#mobile-logout-btn', 'click', this.onLogoutChosen, this);
    }

    public getDOMRoot() {
        return this.root.getDOMRoot();
    }

    public refresh() {
        this.root.getDOMRoot().innerHTML = this.render();

        if (this.onSitePage) {
            // @ts-ignore We still depend on site jquery plugins for the flyout menu, so reinitialize after a refresh
            window.asideNavSubmenus();
        }
        else {
            this.applyMenuBehavior();
        }
    }

    protected render() {
        let user: (User & { initials?: string}) | null = this.getTypedData('user');

        if (user !== null) {
            const initials = user?.displayName?.split(' ').map((name) => { return name.charAt(0)}).join('');

            user.initials = initials;
        }

        return MobileNavTemplate({ navigation: this.navigationData, userNavigation: this.userNavigationData, pageHasLoaded: true, user });
    }

    protected applyMenuBehavior() {
        // @ts-ignore
        var timer = null;

        $('#main-aside-menu > ul li > a').click(function(e) {
          if ($(this).hasClass('is-open')) {
            $(this).removeClass('is-open');
            $(this).next().slideUp(300);
          } else {
            $(this).parent('.submenu').parent().find('.is-open').next().slideUp(300);
            $(this).parent('.submenu').parent().find('.is-open').removeClass('is-open');
            if($(this).parent().hasClass('submenu')) $(this).addClass('is-open');
            $(this).next().slideDown(300);
          }
          // @ts-ignore
          clearTimeout(timer);
          //cancel the previous timer.
          timer = null;
          timer = setTimeout(function () {
            $('#main-aside-menu > ul li > a').removeClass('is-open');
            $('#main-aside-menu > ul li > a').next().slideUp();
          }, 50000);
          if($('body').hasClass('one-page') || $('body').hasClass('slider-page')){
            if ($('body').hasClass('mobile-menu-open')) {
                $('.toggle-menu').removeClass('active');
                $('body').removeClass('mobile-menu-open');
                $('body').addClass('mobile-menu-closing');
                setTimeout(function () {
                  if ($('body').hasClass('mobile-menu-closing') === true) {
                    $('body').removeClass('mobile-menu-closing');
                  }
                }, 500);
              }
          }
        });
    }

    protected onLogoutChosen() {
        this.triggerEvent('CLASS_EVENT', 'onSignoutChosen', null);
    }

}