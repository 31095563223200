import { User } from "firebase/auth";

import { Component } from "@subgraph-io/edge/component/Component.js";
import { JadeAPISignatures } from "@sixclear/server/src/shared-types/jade/api-types";
import { BundleSpecification } from "@sixclear/server/src/shared-types/jade/types";
import { PostAPIClient } from "@sixclear/server/src/shared-interfaces/post-api/client";

export type SixclearUser = User & { organization: string }

export type SignUpFields = {
    name: string,
    email: string,
    password: string,
    organization: string
}

export type SignInFields = {
    email: string,
    password: string,
    user?: User
}

export type ResetPasswordFields = {
    email: string
}

export type ResetPasswordResponsePayload = {
    ok: boolean,
    errors: RequestErrors
}

export type ChangeEmailResponsePayload = ResetPasswordResponsePayload;

type RequestError = { message: string }
type RequestErrors = RequestError[]

export type SignupResponsePayload = {
    ok: boolean,
    token: string | null,
    errors: RequestErrors
}

export type SignInResponsePayload = {
    ok: boolean,
    token: string | null,
    errors: RequestErrors
}

export type SignoutResponsePayload = {
    success: boolean,
    session_id: string,
    email: string,
    message: string
}

export type PortalProduct = {
    id: string,
    name: string,
    zip_url: string,
    zip_password: string
}

export type PortalApplication = {
    id: string,
    name: string,
    url: string,
    course_details?: any,
    certificates_info?: any[]
    renew_form_url?: string
    buy_form_url?: string
}

export type AccountData = {
    ok: boolean,
    errors: RequestErrors,
    user: SixclearUser,
    products: {
        fli_driver?: PortalProduct
    },
    applications: {
        certificates?: PortalApplication,
        training?: PortalApplication
    }
}

export type UserUpdateFields = { email: string } | { displayName: string, phoneNumber: string, organization: string }

export class API {

    public jade: PostAPIClient<JadeAPISignatures>;

    constructor() {
        this.jade = new PostAPIClient({ url: '/api/jade' });
    }

    public async signUp(fields: SignUpFields): Promise<SignupResponsePayload> {
        const response = await fetch('/api/auth/signup', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(fields)
        });

        return await response.json();
    }

    public async signOut(): Promise<SignoutResponsePayload> {
        const response = await fetch('/api/auth/signout', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return await response.json();
    }

    public async signIn(fields: SignInFields): Promise<SignInResponsePayload> {
        const response = await fetch('/api/auth/signin', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(fields)
        });

        return await response.json();
    }

    public async resetPassword(fields: ResetPasswordFields): Promise<ResetPasswordResponsePayload> {
        const response = await fetch('/api/auth/reset-password', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(fields)
        });

        return await response.json();
    }

    public async changeEmail(email: string): Promise<ChangeEmailResponsePayload> {
        const response = await fetch('/api/auth/change-email', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email })
        });

        return await response.json();
    }

    public async fetchAccountData(): Promise<AccountData> {
        const response = await fetch('/api/account/get-account-data', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return await response.json();
    }

    public async updateAccountData(fields: UserUpdateFields): Promise<AccountData> {
        const response = await fetch('/api/account/update-account-data', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(fields)
        });

        return await response.json();
    }

    public async generateJADEBundleWithStatusPolling(bundleSpecification: BundleSpecification): Promise<Component> {
        const generateBundleResponse = await this.jade.sendRequest('GenerateBundle', bundleSpecification);

        if (!generateBundleResponse.ok) {
            throw generateBundleResponse.errors;
        }

        const progressPublisher = new Component();

        const checkStatus = async () => {
            const getBundleResponse = await this.jade.sendRequest('GetBundle', { id: generateBundleResponse.data.id });
            let numberOfFailedPolls = 0;

            if (!getBundleResponse.ok || getBundleResponse.data === null) {
                numberOfFailedPolls++;

                if (numberOfFailedPolls >= 5) {
                    return;
                }

                setTimeout(checkStatus, 2000);
            }
            else {
                progressPublisher.setData('', getBundleResponse.data);

                if (getBundleResponse.data.status === 'Generated' || getBundleResponse.data.status === 'Errored') {
                    setTimeout(() => progressPublisher.destroy(), 2000);
                }
                else {
                    setTimeout(checkStatus, 2000);
                }
            }
        }

        setTimeout(checkStatus, 2000);
        setTimeout(() => {
            progressPublisher.setData('', generateBundleResponse.data);
        }, 0);

        return progressPublisher;

    }

    public getJadeDownloadUrl(id: number) {
        return `/api/jade/jade?id=${id}`
    }

    public getJadePluginDeveloperKitDownloadUrl(id: number) {
        return `/api/jade/plugin-developer-kit?id=${id}`
    }

    public getJadeBundleDownloadUrl(id: number) {
        return `/api/jade/jadb?id=${id}`
    }

    /**
     * This api call was written assuming cloud functions could support streaming of the http response.  Keeping it around
     * for when we do a k8s implementation and are not limited by the cloud functions buffering load balancer
     */
    // public async generateJADEBundleWithStreamingResponse(bundleSpecification: BundleSpecification): Promise<Component> {
    //     const response = await fetch('/api/jade/generate-bundle', {
    //         method: 'POST',
    //         cache: 'no-cache',
    //         credentials: 'include',
    //         headers: {
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(bundleSpecification)
    //     });

    //     if (response.body === null) {
    //         throw new Error('JADE Bundle Generator api returned a null response body');
    //     }

    //     const reader = response.body.getReader();
    //     const decoder = new TextDecoder();
    //     const progressPublisher = new Component();

    //     let currentlyStreamingProgressMessageText = '';

    //     const read = async () => {
    //         const { done, value } = await reader.read();

    //         if (done) {
    //             return;
    //         }

    //         let messageText;
    //         const newestChunkText = decoder.decode(value);
    //         const indexOfTerminationCharacterInChunkText = newestChunkText.indexOf('\r');

    //         if (indexOfTerminationCharacterInChunkText !== -1) {
    //             messageText = currentlyStreamingProgressMessageText + newestChunkText.slice(0, indexOfTerminationCharacterInChunkText);
    //             currentlyStreamingProgressMessageText = newestChunkText.slice(indexOfTerminationCharacterInChunkText + 1);
    //         }
    //         else {
    //             currentlyStreamingProgressMessageText += newestChunkText;
    //         }

    //         if (messageText !== undefined) {
    //             let message;

    //             try {
    //                 message = JSON.parse(messageText) as JADEBundleProgressMessage;
    //             }
    //             catch (error) {
    //                 console.error(`Failed to JSON parse bundle generator message: ${messageText}`);
    //                 throw error;
    //             }

    //             progressPublisher.setData('messageLog', message, 'append');
    //         }

    //         read();

    //     };

    //     read();

    //     return progressPublisher;

    // }
}