import JSONBig from "json-bigint";
import { OkResponse, ErrorResponse } from "./types";

export type PostAPISignatures = {
    [requestName: string]: {
        Request: any,
        Response: ( OkResponse | ErrorResponse )
    }
}
export class PostAPIClient<Signatures extends PostAPISignatures> {

    private url: string;

    constructor({ url }: { url: string }) {
        this.url = url;
    }

    public async sendRequest<T extends Signatures, K extends keyof T = keyof T>(requestName: K, requestData: T[K]['Request'], extraHeaders?: { [headerName: string]: string }): Promise<T[K]['Response']> {
        const headers = Object.assign({
            'Content-Type': 'application/json'
            },
            extraHeaders
        );

        const response = await fetch(`${this.url}?r=${String(requestName)}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(requestData, (key, value) => (typeof value === 'bigint' ? value.toString() : value))
        });
        const responseText = await response.text();

        return JSONBig.parse(responseText);

    }

}