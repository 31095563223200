
        
    const runtime = require("../static-site/site-client-src/node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["header-nav.njk"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<header id=\"header\">\n    <div id=\"main-navigation\">\n        <div class=\"main-nav-wrapper\">\n            <div class=\"container-fluid\">\n\n                <div class=\"nav-left\">\n                    <div id=\"logo\" style=\"padding-right:10px;\">\n                    <a href=\"/\" data-logo-light=\"/assets/img/logo/sixclear/light.svg\" data-logo-dark=\"/assets/img/logo/sixclear/dark.svg\">\n                        <img width=\"145\" height=\"36\" src=\"/assets/img/logo/sixclear/light.svg\" alt=\"logo\">\n                    </a>\n                    </div>\n                    <nav id=\"main-menu\">\n                    <ul>\n                        ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "navigation");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n                        <li class=\"mega-menu menu-layouts\" style=\"width: ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"width"), env.opts.autoescape);
output += "px; overflow: visible; text-align: center;\">\n                        ";
if(runtime.memberLookup((t_4),"link")) {
output += "\n                            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link"), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "subitem")),"target")) {
output += "target=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "subitem")),"target"), env.opts.autoescape);
output += "\"";
;
}
output += " style=\"text-align: center;\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title"), env.opts.autoescape);
output += " ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "subitem")),"target")) {
output += "<span class=\"fas fa fa-external-link-alt\" style=\"margin-left:5px;\"></span>";
;
}
output += "</a>\n                        ";
;
}
else {
output += "\n                            <a href=\"#\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title"), env.opts.autoescape);
output += "</a>\n                        ";
;
}
output += "\n                        ";
if(runtime.memberLookup((t_4),"subNavigation")) {
output += "\n                            <div class=\"mega-menu-content col-1\" style=\"";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_4),"menuStyle")), env.opts.autoescape);
output += "\">\n                                <div class=\"menu-buttons\">\n                                    ";
frame = frame.push();
var t_7 = runtime.memberLookup((t_4),"subNavigation");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("navItem", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                                    <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"link"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_8),"fragment"), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_8),"target")) {
output += "target=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"target"), env.opts.autoescape);
output += "\"";
;
}
output += " class=\"menu-button ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_8),"classes")), env.opts.autoescape);
output += "\" style=\"";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_8),"menuStyle")), env.opts.autoescape);
output += "\">\n                                    <div class=\"menu-button-inner\">\n\n                                        ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_8),"icon")), env.opts.autoescape);
output += "\n\n                                        <h4>";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_8),"headerText")), env.opts.autoescape);
output += " ";
if(runtime.memberLookup((t_8),"target")) {
output += "<span class=\"fas fa fa-external-link-alt\" style=\"margin-left:8px;\"></span>";
;
}
output += "</h4>\n\n                                        ";
if(runtime.memberLookup((t_8),"subText")) {
output += "\n                                        <p>";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_8),"subText")), env.opts.autoescape);
output += "</p>\n                                        ";
;
}
output += "\n\n                                    </div>\n                                    </a>\n                                    ";
;
}
}
frame = frame.pop();
output += "\n                                </div>\n                            </div>\n                        ";
;
}
output += "\n                        </li>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    </ul>\n                    </nav>\n                </div>\n                <div class=\"nav-right\">\n                    <ul class=\"nav-tools\">\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "pageHasLoaded")) {
output += "\n                        <li id=\"contact-btn\">\n                            <a href=\"/contact-us\">\n                                <button type=\"button\" class=\"btn btn-rounded btn-primary\" style=\"margin-right:0px; margin-left:0px;\">Contact</button>\n                            </a>\n                        </li>\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "user")) {
output += "\n                        <li id=\"logged-in-btn\">\n                            <a id=\"logged-in-icon-btn\">\n                                <div class=\"circle\">\n                                    <p class=\"circle-inner\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"initials"), env.opts.autoescape);
output += "</p>\n                                </div>\n                                <i class=\"fas fa-angle-down\"></i>\n                            </a>\n                            <!-- <a id=\"logged-in-icon-btn\">\n                                <button type=\"button\" class=\"btn btn-rounded btn-success\"><i class=\"fa fa-user\"></i>&nbsp;&nbsp;<i class=\"fas fa-angle-down\"></i></button>\n                            </a> -->\n                        </li>\n                        ";
;
}
output += "\n                        ";
if(!runtime.contextOrFrameLookup(context, frame, "user")) {
output += "\n                        <li id=\"signup-btn\">\n                            <a href=\"/views/auth/signup\">\n                            <button type=\"button\" class=\"btn btn-rounded btn-primary\" style=\"margin-right:0px\">Sign up</button>\n                            </a>\n                        </li>\n                        <li id=\"login-btn\">\n                            <a href=\"/views/auth/signin\">\n                            <button type=\"button\" class=\"btn btn-rounded btn-success\">Login</button>\n                            </a>\n                        </li>\n                        ";
;
}
output += "\n                        <li class=\"mobile-menu-btn\">\n                            <button class=\"toggle-menu\" data-toggle=\"mobile-menu\" data-effect=\"hover\">\n                            <i class=\"nc-icon-outline ui-2_menu-35\"></i>\n                            </button>\n                        </li>\n                        ";
;
}
output += "\n                    </ul>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div id=\"account-menu-dropdown\" class=\"dropdown-menu\">\n        <div id=\"arrow\" data-popper-arrow></div>\n        <h6 id=\"logged-in-menu-display-name\" class=\"dropdown-header\">";
if(runtime.contextOrFrameLookup(context, frame, "user")) {
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"displayName"), env.opts.autoescape);
;
}
output += "</h6>\n        ";
frame = frame.push();
var t_11 = runtime.contextOrFrameLookup(context, frame, "userNavigation");
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("item", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n            <a class=\"dropdown-item\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"link"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_12),"desktopMenuTitle")), env.opts.autoescape);
output += "</a>\n        ";
;
}
}
frame = frame.pop();
output += "\n        <div class=\"dropdown-divider\"></div>\n        <a class=\"dropdown-item\" id=\"desktop-logout\" href=\"#\">Logout&nbsp;&nbsp;<i class=\"fas fa-sign-out-alt\"></i></a>\n    </div>\n</header>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "header-nav.njk",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "header-nav.njk",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["header-nav.njk"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    