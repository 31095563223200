
        
    const runtime = require("../static-site/site-client-src/node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["mobile-nav.njk"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<aside id=\"aside-nav\">\n    <div id=\"main-aside-navigation\">\n    <div class=\"main-nav-wrapper\">\n        <div class=\"close-aside-nav\">\n        <i class=\"nc-icon-outline ui-1_simple-remove\"></i>\n        </div>\n        <div id=\"aside-logo\">\n        <a href=\"/\" data-logo-light=\"/assets/img/logo/sixclear/light.svg\" data-logo-dark=\"/assets/img/logo/sixclear/dark.svg\">\n            <img width=\"170\" height=\"166\" src=\"/assets/img/logo/sixclear/dark.svg\" alt=\"logo\">\n        </a>\n        </div>\n        <nav id=\"main-aside-menu\">\n\n        <ul>\n            ";
if(runtime.contextOrFrameLookup(context, frame, "user")) {
output += "\n            <li class=\"submenu account\" style=\"margin-bottom: 20px;\">\n                <a id=\"mobile-logged-in-icon-btn\" href=\"#\">\n                    <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"displayName"), env.opts.autoescape);
output += "</span>\n                    <div class=\"circle\">\n                        <p class=\"circle-inner\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"initials"), env.opts.autoescape);
output += "</p>\n                    </div>\n                </a>\n                <ul>\n                    ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "userNavigation");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n                    <li><a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_4),"mobileMenuTitle")), env.opts.autoescape);
output += "</a></li>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </ul>\n            </li>\n            ";
;
}
output += "\n            ";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "navigation");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("item", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n            <li class=\"submenu\">\n            <a class=\"menu-title\" href=\"#\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"title"), env.opts.autoescape);
output += "</a>\n            ";
if(runtime.memberLookup((t_8),"subNavigation")) {
output += "\n                <ul>\n                ";
frame = frame.push();
var t_11 = runtime.memberLookup((t_8),"subNavigation");
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("subItem", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n                <li>\n                    <a href=\"";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_12),"link")), env.opts.autoescape);
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_12),"fragment")), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_12),"target")) {
output += "target=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"target"), env.opts.autoescape);
output += "\"";
;
}
output += ">";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("striptags").call(context, runtime.memberLookup((t_12),"headerText"))), env.opts.autoescape);
if(runtime.memberLookup((t_12),"target")) {
output += "<span class=\"fas fa fa-external-link-alt\" style=\"margin-left:8px; padding-bottom: 2px; font-size: 12px;\"></span>";
;
}
output += "</a>\n                </li>\n                ";
;
}
}
frame = frame.pop();
output += "\n                </ul>\n            ";
;
}
output += "\n            </li>\n            ";
;
}
}
frame = frame.pop();
output += "\n        </ul>\n        <ul style=\"margin-top: 40px;\">\n            <li id=\"mobile-contact-btn\">\n                <a href=\"/contact-us\" class=\"text-primary\">Contact</a>\n            </li>\n            ";
if(!runtime.contextOrFrameLookup(context, frame, "user")) {
output += "\n            <li id=\"mobile-login-btn\">\n                <a href=\"/views/auth/signin\" class=\"text-success\">Login</a>\n            </li>\n            <li id=\"mobile-signup-btn\">\n                <a href=\"/views/auth/signup\" class=\"text-primary\">Signup</a>\n            </li>\n            ";
;
}
output += "\n            ";
if(runtime.contextOrFrameLookup(context, frame, "user")) {
output += "\n            <li class=\"mobile-logout-btn\">\n                <a href=\"#\" class=\"text-primary\">Logout <i class=\"fas fa-sign-out-alt\"></i>&nbsp;</a>\n            </li>\n            ";
;
}
output += "\n        </ul>\n        </nav>\n    </div>\n    </div>\n</aside>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "mobile-nav.njk",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "mobile-nav.njk",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["mobile-nav.njk"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    